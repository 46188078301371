<template>
	<!-- 公共部分-提bug -->
<div>
	<GModule class="container">
		<GTitle name="提BUG" style="margin: 20px;"></GTitle>
		<div class="bug-box">
			<a-form
				class="need-box"
			    :model="formState"
			    name="basic"
			    :label-col="{ span: 7}"
			    :wrapper-col="{ span: 12 }"
			    autocomplete="off"
				:scrollToFirstError='true'
			    @finish="onFinish">
			    <a-form-item label="BUG等级">
			        <a-dropdown v-if="productArr">
			            <template #overlay>
			                <a-menu selectable @click="handleMenuClick">
			        			<a-menu-item v-for="(pitem,pindex) in productArr" :key="pindex">
									{{pitem}}
								</a-menu-item>
			                </a-menu>
			            </template>
			            <a-button>
			        		{{productArr[stateIndex]}}
			                <DownOutlined />
			            </a-button>
			        </a-dropdown>
			    </a-form-item>
				<a-form-item label="指派给">
				    <!-- <a-dropdown v-if="manArr.length > 0">
				        <template #overlay>
				            <a-menu selectable @click="handleMan">
				    			<a-menu-item v-for="(mitem,mindex) in manArr" :key="mindex">
									{{mitem.nickname}}
								</a-menu-item>
				            </a-menu>
				        </template>
				        <a-button>
				    		{{manArr[manIndex].nickname}}
				            <DownOutlined />
				        </a-button>
				    </a-dropdown> -->
					<a-checkbox
						:checked="item.isCheck" 
						v-for="(item,index) in groupArr" 
						:key="index"
						@click="changeClick(index)">
						{{item.nickname}}
					</a-checkbox>
				</a-form-item>
				<a-form-item 
					label="BUG描述" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: true, message: '请输入BUG描述' }]">
				    <a-textarea placeholder="请输入BUG描述" style="height: 200px;" v-model:value="formState.describe" />
				</a-form-item>
				<a-form-item label="图片(选填)">
				    <a-upload
						name="file"
						v-model:file-list="fileList"
						:maxCount='8'
						list-type="picture-card"
						:customRequest ="customRequest"
						@remove="remove">
						<div class="addimg" v-if="fileList.length < 8">
							<plus-outlined />
							<p style="color: #999999;">点击上传图片</p>
						</div>
				    </a-upload>
				</a-form-item>
				
				<a-form-item label="需求模块">
					<div v-if="modularsArr.length == 0" style="color:#999;">请在左侧模块菜单选择需求点（第三级）</div>
				    <a-breadcrumb separator=">"	style="width: 400px;margin-bottom: 14px;" v-else>
				        <a-breadcrumb-item 
							v-for="(item,index) in modularsArr" 
							:key="index">{{item}}
						</a-breadcrumb-item>
				    </a-breadcrumb>
				</a-form-item>
				<a-form-item label="需求描述">
				    <a-textarea style="height: 150px;" :value="mInfo.describe" disabled/>
				</a-form-item>
				<a-form-item label="参数/逻辑说明">
				    <a-textarea style="height: 90px;" :value="mInfo.explain" disabled/>
				</a-form-item>
				<a-form-item label="需求图片">
				    <div class="img-scroll" @click="openSwiper()">
				    	<a-image :preview="false" :width="90" :src="itm" v-for="(itm,ind) in mInfo.imgArr" :key="ind"/>
				    </div>
				</a-form-item>
				<a-form-item label="相关用例">
				    <div class="case-box">
				    	<a-tabs v-model:activeKey="activeKey">
				    		<a-tab-pane 
								v-for="(i,index) in caseList" 
								:key="index" 
								:tab="'用例'+i.id">
								<p>1. 用例摘要：</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;{{i.describe}}</p>
								<p>2. 前置条件：</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;{{i.describe}}</p>
								<p>3. 操作步骤：</p>
								<p v-for="(sitm,sindex) in i.steps" :key="sindex">
									&nbsp;&nbsp;&nbsp;&nbsp;({{sitm.index}}). {{sitm.content}}
								</p>
							</a-tab-pane>
				    	</a-tabs>
				    </div>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:10 }">
				  <a-button class="btn clo3" html-type="submit">提交</a-button>
				  <a-button class="btn clo6" @click="cancel()">取消</a-button>
				</a-form-item>
			</a-form>
		</div>
	</GModule>
</div>		
</template>

<script>
export default{
	data(){
		return{
			productArr:{}, 
			stateIndex:1,
			manArr:[], 
			manIndex:0,
			formState :{
				describe:''
			},
			caseList:[], //用例列表
			activeKey:0,
			id:0 ,// 模块id
			mInfo:{} ,// 需求模块详情
			modularsArr:[] ,// 修改用的需求模块
			fileList:[] ,// 上传图片file列表
			imgs:[] ,// 已上传的图片
			changeId:0 ,// 测试用例id
			againTxt:'' ,// 原bug描述
			againId:0 ,// 原bugid
			againDid:0 ,// 原需求模块id
			groupArr:[] // 指派人列表
		}
	},
	computed:{
		proId(){
			return this.$store.state.proId  
		},
		selectedMemu(){
			return this.$store.state.selectedMemu
		},
		mId(){
			if(this.selectedMemu.length==3){
				return this.selectedMemu[this.selectedMemu.length - 1].demand[this.selectedMemu[this.selectedMemu.length - 1].demand.length-1].id
			}else{
				return 0;
			}
		}
	},
	watch:{
		"mId":{
			handler(val,oldvalue){
				if(val){
					this.againTxt = '' // 清空原bug描述标识
					this.getModal(val)
					this.id = val
				}
			},
			deep:true
		}
	},
	async created() {
		this.id = this.$route.query.id || this.mId || 0  
		this.changeId = this.$route.query.changeId || 0
		this.againId =  this.$route.query.againId || 0 // againId 原bugid
		this.againTxt = this.$route.query.txt || '' //原bug描述标识
		this.formState.describe = this.againTxt //原bug描述
		// else if 判断避免左侧模块id污染
		if(this.againId){
			this.getModal(this.againId)
		}else if(this.id){
			this.getModal(this.id)
		}
	
		// 获取等级
		let r = await this.$utils.api.post({
			url:'/bug/setting',
			result:1
		})
		if(r.code == 1){
			this.productArr =  r.data.levelList
		}
		// 获取指派人
		let rr = await this.$utils.api.post({
			url:'/team/team_selectpage',
			data:{
				project_id: this.proId,
				label:'develop'
			},
			result:1
		})
		if(rr.code == 1){
			this.manArr = rr.data.list
			for(let i in rr.data.list){
				rr.data.list.isCheck = false
			}
			this.groupArr = rr.data.list
		}
	},
	methods:{
		handleMenuClick(e){
			this.stateIndex = e.key 
		},
		handleMan(e){
			this.manIndex = e.key
		},
		openSwiper(){
			// 弹出图片列表
			this.$store.commit('setSwiperArr',this.mInfo.imgArr)
			this.$store.commit('setSwiper',1)
		},
		async getModal(id){
			// 根据模块id请求需求详情
			let res = await this.$utils.api.post({
				url: this.againTxt ? '/bug/getRow' :'/demand/getRow',
				data:{
					ids:id
				},
				result:1
			})
			if(res.code == 1){
				if(this.againTxt){
					// 重提bug
					this.fileList = []
					for(let i in res.data.images){
						let item = {
							status:'done',
							thumbUrl:res.data.images[i]
						}
						this.fileList.push(item) // 回显原bug图片
					}
					this.imgs = res.data.images // 原bug图片，用于提交
					// 原需求图片
					res.data.imgArr = res.data.demand.images.length > 0 ? res.data.demand.images : []
					this.modularsArr = res.data.demand.modulars // 原需求模块
					this.againDid = res.data.demand.id // 原需求模块id
				}else{
					// 提bug
					res.data.imgArr = res.data.images.length > 0 ? res.data.images.split(',') : []
					this.modularsArr = res.data.modulars
				}
				this.mInfo = res.data
				this.caseList = res.data.cases
			}
		},
		async customRequest (e) {
			// 执行图片上传
			let that = this
			let res = await this.$utils.api.upLoad(e)
			if(res){
				setTimeout(()=>{
					that.$utils.msgSuc('上传成功')
					// 上传成功修改文件状态
					that.fileList[that.fileList.length-1].status = 'done' 
					// 图片封面预览
					that.fileList[that.fileList.length-1].thumbUrl = res.data.fullurl 
					// 图片集合
					that.imgs.push(res.data.fullurl)
				},500)
			}
		},
		remove(e){
			// 删除图片
			this.imgs = this.imgs.filter((item) => {
			    return item != e.thumbUrl
			})
		},
		changeClick(index){
			// 选择指派人
			this.groupArr[index].isCheck = !this.groupArr[index].isCheck
		},
		async onFinish(e){
			let uids = []
			for(let i in this.groupArr){
				if(this.groupArr[i].isCheck){
					uids.push(this.groupArr[i].id)
				}
			}
			if(uids.length == 0){
				this.$utils.msgWar('请选择指派人')
				return
			}
			if(this.modularsArr.length == 0){
				this.$utils.msgWar('请在左侧模块菜单选择需求点（第三级）')
				return
			}
			let info = {
				'row':{
					'level': this.stateIndex,
					'demand_id':this.againTxt ? this.againDid : this.id, 
					'user_ids':uids, // 'user_id':this.manArr[this.manIndex].id
					'describe':this.formState.describe,
					'images': this.imgs
				},
				case_id:this.changeId,
				bug_id: this.againId ? this.againId : 0  // againId 表示原bugid的标识
			}
			let res = await this.$utils.api.post({
				url:'/bug/add',
				data:info,
				result:1
			})
			if(res.code == 1){
				this.$utils.msgSuc('提交成功')
				this.cancel()
			}
		},
		cancel(){
			// 取消
			this.$router.back()
		}
	}
}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0;
	overflow-y: auto;
	overflow-x: hidden;
	.bug-box{
		.need-box{
			.btn{
				margin: 30px 10px;
			}
			.img-scroll{
				height: 90px;
				overflow-x: auto;
				overflow-y: hidden;
				display: flex;
				align-items: center;
				&::-webkit-scrollbar {
					height: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #a5ddff; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						height: 8px;
					
					}
				}
			}
		}
	}
	.case-box{
		border: 1px solid #ddd;
		padding: 0 20px;
	}
}
</style>